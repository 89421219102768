import { effect, Injectable, signal } from '@angular/core';

import { Theme } from '@shared/models/theme.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public theme = signal((localStorage.getItem('pl-theme') as Theme) ?? Theme.light);
  private themeChanges = effect(() => localStorage.setItem('pl-theme', this.theme()));

  public setTheme(theme: Theme): void {
    this.theme.set(theme);
  }
}
